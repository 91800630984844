<template>
<!-- main -->
<main id="container">
    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <h3 class="tit_section">100년 기업으로 가는 길.<br> 중소기업 CEO 여러분을 초청합니다. </h3>
            <p class="desc_section">대한민국 법인 컨설팅 전문가 그룹 매경경영지원본부의 경영 철학 및 실전 전략을 알 수있는 세미나를 개최하여<br> 중소기업 CEO분들에게 힘이 되어 드리겠습니다.</p>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 최신뉴스 -->
    <section id="topNews" class="section">
        <div class="inner clearfix">
            <p class="tag_section"></p>
            <h3 class="tit_section"></h3>
            <div class="box_thumb">
                <img src="/img/seminar/seminar/topnews_thumb.jpg" alt="">
                <i class="particle_circle-border" style="top:-20px;left:19px"></i>
                <i class="particle_circle" style="top:118px;right:-30px"></i>
                <i class="particle_circle-diagonal" style="bottom:0px;right:-129px"></i>
                <p class="seminar_tit"></p>
            </div>
            <div class="position-relative float-end">
                <ul class="swiper">
                    <li class="item_news">
                        <a class="link_news hidden">
                            <p class="local" data-text="/0/content" data-clamp="1"></p>
                            <p class="date"></p>
                            <p class="address" data-html="/0/location" data-clamp="2"></p>
                            <p class="desc" data-html="/0/shortContent" data-clamp="2"></p>
                        </a>
                        <a class="link_news hidden">
                            <p class="local" data-text="/1/content" data-clamp="1"></p>
                            <p class="date"></p>
                            <p class="address" data-html="/1/location" data-clamp="2"></p>
                            <p class="desc" data-html="/1/shortContent" data-clamp="2"></p>
                        </a>
                    </li>
                </ul>
                <!-- Add Pagination -->
                <div class="paging swiper-pagination"></div>
                <!-- Add Navigation -->
                <button class="arrow swiper-button-prev swiper-button-black" type="button"></button>
                <button class="arrow swiper-button-next swiper-button-black" type="button"></button>
            </div>
        </div>
    </section>
    <!-- //최신뉴스 -->

    <!-- 전체뉴스 -->
    <section id="allNews" class="section">
        <div class="inner clearfix">
            <div class="tab">
                <button class="btn_tab" type="button">2023</button>
                <button class="btn_tab" type="button">2022</button>
                <button class="btn_tab" type="button">2021</button>
                <button class="btn_tab" type="button">2020</button>
                <button class="btn_tab" type="button">2019</button>
                <button class="btn_tab" type="button">2018</button>
                <button class="btn_tab" type="button">2017</button>
                <p class="count">세미나 <span class="num">5</span>건</p>
            </div>
            <ul class="list_news">
                <li class="item_news">
                    <a class="link_news">
                        <div class="box_thumb"></div>
                        <p class="tit" data-text="title" data-clamp="1"></p>
                        <p class="local" data-text="/eachSeminar/content" data-clamp="1"></p>
                        <p class="date" data-text="/eachSeminar/closeTime" data-clamp="1"></p>
                        <p class="address" data-html="/eachSeminar/location" data-clamp="2"></p>
                    </a>
                </li>
            </ul>
        </div>
    </section>
    <!-- //전체뉴스 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){




        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/seminar/seminar.scss';
</style>